import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PricingCard from '../components/PricingCard';
import { tier1, tier2, tier3, tier4 } from '../utils/pricing';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';



export default function Home() {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let fullStop = gsap.timeline({})
        fullStop.from('.full-stop', { xPercent: -50, opacity: 0, duration: .5 })
            .to('.full-stop', { xPercent: -10, delay: .45, duration: .45 })
            .to('.full-stop', { xPercent: 0, duration: .65 });
        gsap.from('.dot', { x: 600, ease: 'bounce', duration: 2.5 });
        gsap.from('.development', { yPercent: 25, delay: 1.75, opacity: 0, duration: .5 });
        gsap.from('.swoop-up', { yPercent: 50, delay: 2.5, ease: 'power4.out', duration: 1.5 })
        gsap.from('.card-col', { scrollTrigger: '.card-row', opacity: 0, stagger: .25, xPercent: 10, ease: 'power4.out', duration: .5 })
    }, [])

    return (
        <main>
            <Container fluid>
                <Row>
                    <Col lg={8} className='mr-auto ml-auto'>
                        <div className='home-aspect'>
                            <div>
                                <h1 className='theme-title'>
                                    <div>
                                        <div className='full-stop title-font m-2 d-inline-block'>Full Stop</div>
                                        <div className='dot d-inline-block'></div>
                                    </div>
                                    <div className='title-font development ml-5'>Development</div>
                                </h1>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='theme-back-light swoop-up pb-5'>
                <Row>
                    <Col lg={9} className='mr-auto ml-auto mt-3'>
                        <h2 className='text-center m-4'>Emphasize your business</h2>
                        <p>Want a great website without the work? Let us handle it so you can get back to what's important. With our suite of options for all business types and sizes we're prepared to tackle any project you have in mind. Need a new, beautiful, responsive website? We've got you. Need someone to manage and update your current site? We've got you. Need to make the jump to eCommerce. We've got you.</p>
                        <p className='text-center'>Our business is to help you grow yours.</p>
                    </Col>
                </Row>
                <Row className='justify-content-center mt-2 card-row'>
                    <Col className='card-col' lg={3}>
                        <PricingCard className='card-pricing' tier={tier1} />
                    </Col>
                    <Col className='card-col' lg={3}>
                        <PricingCard className='card-pricing' tier={tier2} />
                    </Col>
                    <Col className='card-col' lg={3}>
                        <PricingCard className='card-pricing' tier={tier3} />
                    </Col>
                </Row>
                <Row className='justify-content-center mt-2 card-row'>
                    <Col className='card-col' lg={5}>
                        <PricingCard className='card-pricing' tier={tier4} noModal={true} />
                    </Col>
                </Row>
            </Container>
        </main>
    )
}
