import React from 'react';
import '../components/MoreInfoModal';
import { Card, Nav, Button } from 'react-bootstrap';
import MoreInfoModal from '../components/MoreInfoModal';

export default function PricingCard({ tier, noModal = false }) {
  return (
    <Card className='mt-3'>
      <Card.Body>
        <Card.Title>{tier.name}</Card.Title>
        <Card.Text>{tier.desc}</Card.Text>
      </Card.Body>
      <Card.Body className='pt-0'>
        <Card.Text className='h4'>{tier.price}</Card.Text>
      </Card.Body>
      {/* <ListGroup className="list-group-flush">
                <ListGroupItem>Cras justo odio</ListGroupItem>
                <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                <ListGroupItem>Vestibulum at eros</ListGroupItem>
            </ListGroup> */}
      <Card.Body className='text-right border-top'>
        {noModal ? (
          <Nav.Link href='/contact'>
            <Button variant='secondary'>Get started</Button>
          </Nav.Link>
        ) : (
          <MoreInfoModal />
        )}
      </Card.Body>
    </Card>
  );
}
