import react, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavHeader from './components/NavHeader'
import Home from './pages/Home'
import Contact from './pages/Contact';

function App() {

  return (
    <div className='theme-background'>
      <Router>
        <NavHeader />
        <Route exact path='/' component={Home} />
        <Route exact path='/contact' component={Contact} />

      </Router>
    </div>
  );
}

export default App;
