import React, { useState } from 'react';
import { Modal, Button, Nav } from 'react-bootstrap';

export default function MoreInfoModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='secondary' onClick={handleShow}>
        Learn more
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>What's included?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All of our plans include a professionally designed website with no up front cost to you! That's right, you
            pay absolutely nothing until your website is ready to launch.
          </p>
          <p>
            For our service plans, the monthly fee begins the day your website goes live and includes website support for the duration of your
            service plan.
          </p>
          <p>What does this mean?</p>
          <ul>
            <li>If you need a change, we're ready to help.</li>
            <li>You want something added, or removed? No sweat.</li>
            <li>Questions or comments? We're here for you.</li>
          </ul>
          <p>
            When you sign up for your managed plan you will have direct access to support staff and can expect any minor
            changes requested to be completed within 48 hours.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Nav.Link href='/contact'>
            <Button variant='secondary'>Get started</Button>
          </Nav.Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
