import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { db, serverTimestamp, collection, addDoc } from '../utils/firebase';

export default function Contact() {
  gsap.registerPlugin(ScrollTrigger);
  const [validated, setValidated] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const fName = useRef();
  const lName = useRef();
  const email = useRef();
  const message = useRef();
  const referral = useRef();

  const sendMessage = async (fName, lName, email, message, referral) => {
    try {
      const newMsg = await addDoc(collection(db, 'leadEmails'), {
        fName,
        lName,
        email,
        message,
        referral,
        sent: serverTimestamp(),
      }).then(() => {
        setDisplayConfirmation(true);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const customValidations = () => {
    if (
      !fName.current.value ||
      !lName.current.value ||
      !email.current.value ||
      !message.current.value ||
      !referral.current.value
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(false);
    }
    setValidated(true);

    if (customValidations() === true) {
      sendMessage(
        fName.current.value,
        lName.current.value,
        email.current.value,
        message.current.value,
        referral.current.value
      );
    }
  };

  useEffect(() => {
    let fullStop = gsap.timeline({});
    fullStop.from('.full-stop', { xPercent: -50, opacity: 0, duration: 0.5 });
  }, []);

  return (
    <main>
      <Container fluid>
        <Row>
          <Col lg={10} xs={8} className='mr-auto ml-auto'>
            <div className='contact-aspect'>
              <div>
                <h1 className='theme-title'>
                  <div>
                    <div className='full-stop contact-title-font m-2 d-inline-block'>Full Stop Development</div>
                  </div>
                </h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className='theme-back-light swoop-up pb-5'>
        <Row>
          <Col lg={9} className='mr-auto ml-auto mt-3'>
            {displayConfirmation && (
              <Modal.Dialog className='mt-5'>
                <Modal.Header>
                  <Modal.Title className='ml-auto mr-auto'>Your message has been sent!</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>Thank you for reaching out. You can expect an email from us shortly to set up your free consultation.</p>
                </Modal.Body>
              </Modal.Dialog>
            )}
            {!displayConfirmation && (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h2 className='text-center m-4'>Drop us a line and get started today</h2>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='formFirstName'>
                      <Form.Label>First name</Form.Label>
                      <Form.Control required ref={fName} type='text' placeholder='Enter first name' />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className='mb-3' controlId='formLastName'>
                      <Form.Label>Last name</Form.Label>
                      <Form.Control required ref={lName} type='text' placeholder='Enter last name' />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control required email ref={email} type='email' placeholder='Enter email' />
                  <Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='formDescription'>
                  <Form.Label>Tell us about your ideal website</Form.Label>
                  <Form.Control
                    required
                    ref={message}
                    as='textarea'
                    rows='6'
                    placeholder='What can we help you build?'
                  />
                </Form.Group>

                <Form.Group className='mb-3' controlId='formFindUs'>
                  <Form.Label>How did you hear about us?</Form.Label>
                  <Form.Control required ref={referral} type='text' placeholder='Search engine, word of mouth, etc.' />
                </Form.Group>
                <Button variant='secondary' type='submit' onClick={handleSubmit}>
                  Send
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
}
