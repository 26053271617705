import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'


export default function NavHeader() {
    return (
        <header>
            <nav>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="/">Full Stop.</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </nav>
        </header>
    )
}
