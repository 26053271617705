export const tier1 = {
  name: 'Single-Page',
  desc: 'Need a clean and modern single page website? This is the tier for you!',
  price: '$149/mo',
};

export const tier2 = {
  name: 'Multiple-Page',
  desc: 'Have lots of content to manage and need space to show it off?',
  price: '$199/mo',
};

export const tier3 = {
  name: 'Ecommerce',
  desc: 'Want your website to work for you? Grab this tier and monetize it!',
  price: '$299/mo',
};

export const tier4 = {
  name: 'One Time Purchase',
  desc: "Do you want a professionally designed website without management services? No sweat, let us know what you're thinking and we can get you a no obligation quote tailored to you!",
  price: 'Contact us for a quote today!',
};
